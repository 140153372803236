<template>
    <div>
        <Loader v-if="loader"></Loader>

        <div v-else-if="loader === false && novosList.length > 0 && novosItem" class="relative-container">
            <h2 v-if="name !== ''" class="card_h2">Ипотека и рассрочка в новостройках {{ name }}</h2>
            <h2 class="card_h2" v-else>{{ heading }}</h2>

            <Form />
            <BankList v-if="starting" />
            <PaymentList v-if="starting && visiblePayments" />
        </div>
    </div>
</template>

<script>
  import Form from './components/FormComponent';
  import BankList from './components/mortgage/BankListComponent';
  import PaymentList from './components/payments/PaymentListComponent';
  import Loader from './components/Loader';
  import store from './store';
  import { mapState } from 'vuex';

  export default {
    store,
    name: 'App',
    components: {
      Form,
      BankList,
      PaymentList,
      Loader,
    },
    props: {
      url: String,
      list: Array,
      company: Number,
      name: String,
      heading: String,
      showRooms: Number,
      optimalParams: Number,
      novosId: Number,
      houseId: Number,
      apartmentId: Number,
      rooms: String,
      price: [String, Number],
      pageItems: Number,
      notAdverts: Array,
      typeLoad: String,
      visibleHouse: Number,
      visiblePayments: Number,
      visibleRooms: Number,
      visiblePrice: Number,
      visibleBuild: Number,
      visibleLeader: Number,
      visibleAdvert: Number,
      tools: Object,
      sellers: {
        type: Array,
        default: () => []
      },
      rc: {
        type: Number,
        default: 0
      },
      defaultParams: {
        type: Object, // { sum: Number, fee: Number, period: Number }
        default: () => {}
      },
      mpnType: String,
      moreBtnRc: {
        type: Number,
        default: 0
      },
    },
    computed: mapState({
      loader: 'loader',
      novosList: 'novosList',
      novosItem: 'novosItem',
    }),
    data: () => ({
      starting: false,
    }),
    async created() {
      this.$store.commit('setUrl', this.url);
      this.$store.commit('setNotAdverts', this.notAdverts);
      this.$store.commit('setShowPrice', +this.visiblePrice > 0);
      this.$store.commit('setVisibleHouse', +this.visibleHouse > 0);
      this.$store.commit('setVisibleRooms', +this.visibleRooms > 0);
      this.$store.commit('setVisibleBuild', +this.visibleBuild > 0);
      this.$store.commit('setOptimalParams', +this.optimalParams > 0);
      this.$store.commit('setVisibleLeader', +this.visibleLeader > 0);
      this.$store.commit('setVisibleAdvert', +this.visibleAdvert > 0);
      this.$store.commit('setNovosId', +this.novosId);
      this.$store.commit('setHouseId', +this.houseId);
      this.$store.commit('setApartmentId', +this.apartmentId);
      this.$store.commit('setSellers', this.sellers);
      this.$store.commit('setCompanyId', +this.company);
      this.$store.commit('setPrice', +this.price);
      this.$store.commit('setTypeLoad', this.typeLoad);
      this.$store.commit('setPageItems', this.pageItems);
      this.$store.commit('setParams', this.defaultParams);

      if (this.tools) {
        this.$store.commit('setTools', this.tools);
      }

      if (+this.rc > 0) {
        this.$store.commit('setRc', this.rc);
      }

      if (+this.moreBtnRc > 0) {
        this.$store.commit('setMoreBtnRc', this.moreBtnRc);
      }

      if (this.list.length) {
        this.$store.commit('setNovosList', this.list);
      }

      this.$store.commit('setMpnType', this.mpnType);

      const item = await this.$store.dispatch('getBuildList');

      await this.$store.dispatch('actionNovos', item);

      this.starting = true;
    },
    mounted() {
      window.emit.emit('list.build.completed', this);
    }
  }
</script>
