<style lang="scss">
.d_n {
  display: none
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.js-row-flex {
  display: flex;
  flex-wrap: wrap;

  .js-col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
</style>

<template>
  <div class="b-box-content mb_20" v-bind="{...wrapperProps}">
    <div class="b-box o_h" :class="{
      'd_n': hideBuild && !isVisibleHouse
    }">
      <div class="b-row-item">
        <div class="row-flex f_w_w" ref="containerFields">
          <div class="col-md-4">
            <label for="some-id" class="b-label-item">Новостройка</label>

            <div class="b-item-box">
              <div class="b-item b-select-item">
                <select
                  :disabled="list.length < 2"
                  @change="changeNovos"
                  class="b-castom-select-item b-castom-field-item"
                >
                  <option
                    v-for="(row, key) in list"
                    :selected="novosItem && row.id === novosItem.id"
                    :value="key"
                  >
                    {{ row.name }}
                  </option>
                </select>
                <div class="b-castom-arrow-item">
                  <svg fill="#017eec" width="24" viewBox="0 0 24 24">
                    <path d="M16.59,8.295L12,12.875l-4.59-4.58L6,9.705l6,6l6-6L16.59,8.295z"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-xs-6" :class="{ 'd_n': !isVisibleHouse }">
                <label for="some-id-1" class="b-label-item">Срок сдачи</label>

                <div class="b-item-box">
                  <div class="b-item b-select-item">
                    <div v-if="novosItem && typeLoad === 'one'">
                      <div v-if="novosItem.houseEach.length > 0">
                        <select
                          ref="houseField"
                          :disabled="novosItem.houseEach.length === 1"
                          name="Calc[house_id]"
                          class="js-mortgage-house b-castom-select-item b-castom-field-item"
                        >
                          <option
                            v-for="(row, key) in novosItem.houseEach"
                            :selected="houseItem && row === houseItem"
                            :value="row.houses.length > 0 ? row.id : 0"
                            :data-key="key"
                          >
                            {{ renderHouseItem(row) }}
                          </option>
                        </select>
                        <div class="b-castom-arrow-item">
                          <svg fill="#017eec" width="24" viewBox="0 0 24 24">
                            <path d="M16.59,8.295L12,12.875l-4.59-4.58L6,9.705l6,6l6-6L16.59,8.295z"/>
                          </svg>
                        </div>
                      </div>
                      <div v-else>
                        <select
                            :disabled="true"
                            id="some-id-1"
                            class="b-castom-select-item b-castom-field-item"
                        >
                          <option :selected="true">
                            {{ renderHouseItem(novosItem) }}
                          </option>
                        </select>
                        <div class="b-castom-arrow-item">
                          <svg fill="#017eec" width="24" viewBox="0 0 24 24">
                            <path d="M16.59,8.295L12,12.875l-4.59-4.58L6,9.705l6,6l6-6L16.59,8.295z"/>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <!-- @todo Years building -->
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-xs-6"
                v-if="isRoom && houseItem && houseItem.adsPrices.length > 0"
                :class="{ 'd_n': !isVisibleHouse }"
              >
                <label for="some-id" class="b-label-item">Комнатность</label>
                <div class="b-item-box">
                  <div class="b-item b-select-item">
                    <select
                      :disabled="houseItem.adsPrices.length < 2"
                      @change="changeRoom"
                      id="some-id"
                      class="b-castom-select-item b-castom-field-item"
                    >
                      <option
                        v-for="(row, key) in houseItem.adsPrices"
                        :selected="roomItem && roomItem.rooms === row.rooms"
                        :value="key"
                      >
                        {{ row.rooms ? row.label.room7 : 'Любая' }}
                      </option>
                    </select>
                    <div class="b-castom-arrow-item">
                      <svg fill="#017eec" width="24" viewBox="0 0 24 24">
                        <path d="M16.59,8.295L12,12.875l-4.59-4.58L6,9.705l6,6l6-6L16.59,8.295z"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="b-box" v-if="isActiveParams">
      <div class="b-row-item">
        <div class="row">
          <div class="col-md-8" v-if="isShowPrice || summOptions.min !== summOptions.max">
            <div class="row">
              <div class="col-md-6">
                <field
                  label="Стоимость недвижимости"
                  placeholder="Укажите стоимость"
                  append-field="₽"
                  v-model="sum"
                  :options="summOptions"
                  :numberFormat="true"
                  :lazy="true"
                ></field>
              </div>

              <div class="col-md-6">
                <field
                  label="Первый взнос"
                  placeholder="Укажите стоимость"
                  append-field="₽"
                  v-model="fee"
                  :options="feeOptions"
                  :numberFormat="true"
                  :percent="isShowPrice ? getPercentValue : null"
                  :lazy="true"
                ></field>
              </div>
            </div>
          </div>
          <div v-else class="col-md-4">
            <field
              label="Первый взнос"
              placeholder="Укажите стоимость"
              append-field="₽"
              v-model="fee"
              :options="feeOptions"
              :numberFormat="true"
              :percent="isShowPrice ? getPercentValue : null"
              :lazy="true"
            ></field>
          </div>
          <div class="col-md-4" :class="{ 'b-listing-disabled': hideFields }">
            <field
              label="Срок кредита"
              placeholder="Срок кредита"
              append-field="лет"
              v-model="period"
              :options="periodOptions"
              :lazy="true"
            ></field>
          </div>
        </div>
      </div>
    </div>

    <div v-if="allTags.length > 1" class="b-box">
      <div class="b-tags">
        <div v-show="isActiveAddParams" class="tag_check_box mb_20">
          <label
            v-for="tag in allTags"
            :key="tag.id"
            :class="{ active: tags.includes(+tag.id) }"
            class="tag_check checkbox_item btn btn-default"
          >
            <input
              :checked="tags.includes(+tag.id)"
              type="checkbox"
              @change="tags = +tag.id"
            />
            {{ tag.name }}
            <span class="checker_item pos_a top_0 left_0 visible-xs" />
          </label>
        </div>

        <button
          type="button"
          @click="isActiveAddParams = !isActiveAddParams"
          class="fs_14 def_blue underline_link va_t c_p"
        >
          <template v-if="isActiveAddParams">Скрыть</template>
          <template v-else>Типы программ</template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Field from './FieldSliderComponent.vue'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'Form',
  components: { Field },
  mounted () {
    window.emit.emit('mortgage.form.completed', {
      novos: this.novosItem,
      house: this.houseItem
    })

    if (this.$refs.houseField) {
      $(this.$refs.houseField).on('change', e => {
        this.changeHouse(e)
      })
    }

    if (this.tools.show_types_of_programs) {
      this.isActiveAddParams = true;
    }
  },
  data: () => ({
    isActiveParams: false,
    isActiveAddParams: false,
    hideFields: false,
    isChangedSum: false,
    summOptions: {},
    feePercent: 0,
    feeOptions: {},
    periodOptions: {}
  }),
  watch: {
    settings () {
      this.setAllSettings()
    },
    sum () {
      this.isChangedSum = true
      this.$nextTick(() => this.setFee())
    },
    fee () {
      this.feePercent = this.getPercentValue()
    }
  },
  computed: {
    ...mapState({
      list: 'novosList',
      settings: 'settings',
      novosItem: 'novosItem',
      houseItem: 'houseItem',
      roomItem: 'roomItem',
      isRoom: 'isRoom',
      price: 'price',
      typeLoad: 'typeLoad',
      isShowPrice: 'isShowPrice',
      isVisibleHouse: 'isVisibleHouse',
      isVisibleRooms: 'isVisibleRooms',
      isVisibleBuild: 'isVisibleBuild',
      params: 'params',
      showOnlyAdvertiser: 'showOnlyAdvertiser',
      tools: 'tools'
    }),

    ...mapGetters({
      advertiserBanks: 'advertiserBanks',
      isAdvertiser: 'isAdvertiser',
      allTags: 'tags'
    }),

    advertBank () {
      return this.advertiserBanks.length ? this.advertiserBanks[0][0] : null
    },

    wrapperProps () {
      if (this.showOnlyAdvertiser && this.advertBank) {
        return {
          style: {
            [`--${this.advertBank.alias}-color`]: this.advertBank.brand_color
          },
          class: `theme-${this.advertBank.alias}`
        }
      }
      return {}
    },

    hideBuild () {
      return this.list.length < 2 || !this.isVisibleBuild
    },

    sum: {
      get () {
        return this.params.sum
      },
      set (sum) {
        this.$store.commit('setParams', { sum })
      }
    },

    fee: {
      get () {
        return this.params.fee
      },
      set (fee) {
        this.$store.commit('setParams', { fee })
      }
    },

    period: {
      get () {
        return this.params.period
      },
      set (period) {
        this.$store.commit('setParams', { period })
      }
    },

    tags: {
      get () {
        return this.params.tags
      },
      set (tagId) {
        let tags = [...this.params.tags]

        if (tags.includes(+tagId)) {
          tags.splice(tags.indexOf(+tagId), 1)
        } else {
          tags.push(+tagId)
        }

        this.$store.commit('setParams', { tags })
      }
    }
  },
  methods: {
    setAllSettings () {
      this.setPrice()
      this.setFee()
      this.setDefaultFee()
      this.setPeriod()
      this.isActiveParams = true
    },

    setPrice () {
      let settings = { ...this.settings }

      if (this.price > 0) {
        settings.maxPrice = settings.minPrice = this.sum = this.price
      } else {
        this.sum = this.isChangedSum === false && this.sum ? this.sum : settings.minPrice
      }

      this.summOptions = {
        min: settings.minPrice,
        max: settings.maxPrice,
        tooltip: false
      }
    },

    setPeriod () {
      if (this.period === 0) {
        this.period = this.settings.minPeriod > 10 ? 10 : parseInt(this.settings.minPeriod)
      }

      if (this.period > this.settings.maxPeriod) {
        this.period = this.settings.maxPeriod
      }

      this.periodOptions = {
        min: 1,
        max: this.settings.maxPeriod,
        tooltip: false
      }
    },

    setDefaultFee () {
      let maxValue = parseInt(this.sum) * (this.settings.maxFee / 100)

      if (this.fee === 0) {
        this.feePercent = this.settings.maxFee
        this.fee = maxValue
      }
    },

    setFee () {
      let minValue = parseInt(this.sum) * (this.settings.minFee / 100)

      if (this.fee === 0) {
        this.fee = +this.sum * (this.feePercent / 100)
      }

      this.feeOptions = {
        min: minValue,
        max: this.sum,
        tooltip: false
      }
    },

    changeNovos (event) {
      this.fee = 0
      this.$store.dispatch('actionNovos', this.list[event.target.value]).then(() => {
        this.$store.dispatch('getBanks')
      })
    },

    changeHouse (event) {
      this.fee = 0

      let key = $(event.target).find('option:selected').data('key')

      if (!(key in this.novosItem.houseEach)) {
        return false
      }

      this.$store.dispatch('actionHouse', this.novosItem.houseEach[key])
    },

    changeRoom (event) {
      this.fee = 0
      this.$store.dispatch('actionRoom', this.houseItem.adsPrices[event.target.value])
    },

    renderHouseItem (house) {
      if (house.houses.length === 0) {
        return `Любой`
      } else {
        if (parseInt(house.delivered) > 0) {
          return `Корпус ${house.name} (Дом сдан)`
        } else {
          return `Корпус ${house.name} (${house.ready_quarter} кв. ${house.built_year} г.)`
        }
      }
    },

    getPercentValue () {
      return Math.round(this.fee * 100 / this.sum)
    },
  }
}
</script>
