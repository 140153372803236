var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb_10",class:'theme-' + _vm.bank.alias,style:(_vm.cssVars)},[(!_vm.showOnlyAdvertiser && _vm.isPreferentialMortgage)?_c('div',{staticClass:"visible-xs"},[_c('div',{staticClass:"pt_4 pb_4 pl_8 pr_4 map_lgray f_w_nw d_f def_lblack a_i_c m-badge-spec pos_rel"},[_c('div',{staticClass:"mr_8 fs_12 lh_16 fw_m stroke_crop f_g_1"},[_vm._v("Льготная ипотека")]),_c('div',{directives:[{name:"popover",rawName:"v-popover",value:({
        trigger: 'hover',
        placement: 'top',
        content: _vm.getPopoverContent(),
        html: true,
      }),expression:"{\n        trigger: 'hover',\n        placement: 'top',\n        content: getPopoverContent(),\n        html: true,\n      }"}],staticClass:"w_16 d_f a_i_c"},[_c('svg',{staticClass:"w_16 va_m op_5",attrs:{"fill":"rgba(66, 66, 66, 0.5)"}},[_c('use',{attrs:{"xlink:href":"#help-24"}})])])])]):_vm._e(),_c('div',{staticClass:"bank-advertiser"},[_c('div',{staticClass:"d_f j_c_s_b a_i_c pl_20 pr_20 b-listing-col"},[_c('div',{staticClass:" d_f a_i_c stroke_crop"},[_c('div',{staticClass:"mr_16"},[_c('img',{staticStyle:{"height":"32px"},attrs:{"src":_vm.bank.smallLogo,"alt":_vm.bank.bank_name},on:{"error":_vm.notFound}})]),_c('div',{staticClass:"fs_20 lh_28 fw_m stroke_crop"},[_c('div',[_vm._v(_vm._s(_vm.bank.bank_name))]),_c('div',{staticClass:"fs_10 lh_12 name_color",staticStyle:{"line-height":"12px"}},[_vm._v("\n            Лиц. № "+_vm._s(_vm.bank.license)+"\n          ")])])]),(!_vm.showOnlyAdvertiser)?_c('div',{staticClass:"d_f a_i_c"},[(_vm.top)?_c('div',{directives:[{name:"bs-tooltip",rawName:"v-bs-tooltip",value:(_vm.top.title),expression:"top.title"}],staticClass:"ml_10"},[_c('svg',{staticClass:"w_20",attrs:{"fill":_vm.top.color,"width":"20","height":"20"}},[_c('use',{attrs:{"xlink:href":"#cup-24"}})])]):_vm._e(),(_vm.isPreferentialMortgage)?_c('div',{staticClass:"hidden-xs ml_10 pt_4 pb_4 pl_8 pr_4 map_lgray d_f br_4 def_lblack a_i_c"},[_c('div',{staticClass:"mr_8 fs_12 lh_16 fw_m"},[_vm._v("Льготная ипотека")]),_c('div',{directives:[{name:"popover",rawName:"v-popover",value:({
              trigger: 'hover',
              placement: 'top',
              content: _vm.getPopoverContent(),
              html: true,
            }),expression:"{\n              trigger: 'hover',\n              placement: 'top',\n              content: getPopoverContent(),\n              html: true,\n            }"}],staticClass:"w_16 d_f a_i_c"},[_c('svg',{staticClass:"w_16 va_m op_5",attrs:{"fill":"rgba(66, 66, 66, 0.5)"}},[_c('use',{attrs:{"xlink:href":"#help-24"}})])])]):_vm._e()]):_vm._e()]),_vm._m(0),_vm._l((_vm.mortgageList),function(data){return _c('AdvertMortgageItem',{key:data.id,attrs:{"data":data}})}),(_vm.active)?_c('AdvertBankItemAction',{attrs:{"url":_vm.url,"isFrame":_vm.bank.show_frame > 0,"name":_vm.bank.bank_name}}):_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bgr_fafafa pl_20 pr_20 bank-advertiser-border hidden-xs"},[_c('div',{staticClass:" b-box-1-header"},[_c('div',{staticClass:"b-listing-head-item visible-md visible-sm visible-lg"},[_c('div',{staticClass:"b-listing-row-item"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4 b-listing-col"},[_c('div',{staticClass:"b-label-item"},[_vm._v("Программа")])]),_c('div',{staticClass:"col-sm-4 col-xs-6 b-listing-col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"b-label-item"},[_vm._v("Ставка")])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"b-label-item"},[_vm._v("Нач. взнос")])])])]),_c('div',{staticClass:"col-sm-4 col-xs-6 b-listing-col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"b-label-item"},[_vm._v("Срок кредита")])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"b-label-item b-last-col"},[_vm._v("Платеж в месяц")])])])])])])])])])}]

export { render, staticRenderFns }