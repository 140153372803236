<template>
  <div
    class="b-box-listing-content mb_10 bgr_fafafa"
    :class="{ active, 'b-listing-disabled': disabled, 'active-hover': !isAdvertiser }"
  >
    <div class="b-listing-hover-box">
      <div @click="onClickProgram" class="b-listing-header stroke_crop pl_20 pr_20 ">
        <bank-header :item="data" />

        <div class="b-listing-actions">
          <template v-if="!disabled">
            <mortgage-advert-link
              v-if="isAdvertiser"
              :url="data.advert_url"
              :show-frame="data.show_frame > 0"
              :bank-id="+data.bank_id"
            />

            <div v-else class="b-button-toggle b-listing-button" :class="{ 'expanded': active }">
              <svg width="16" height="16" viewBox="0 0 24 24">
                <path d="M16.59,8.295L12,12.875l-4.59-4.58L6,9.705l6,6l6-6L16.59,8.295z"/>
              </svg>
            </div>
          </template>

          <div
            v-else
            class="b-listing-disabled-text"
            v-html="data.errors.join(`<br />`)"
          />
        </div>
      </div>

      <div class="b-listing-body">
        <div @click="onClickProgram" class="b-listing-body-item pl_20 pr_20">
          <mortgage-information :data="data" />
        </div>
      </div>
    </div>

    <div class="b-listing-body-add pl_20 pr_20">
      <mortgage-detail-information
          :data="data"
          :class="{ hide: !active || !data.active }"
        />
    </div>
  </div>
</template>

<script>
  import MobileDetect from 'mobile-detect'
  import BankHeader from '@/modules/mortgage-calc/components/mortgage/item/BankHeader'
  import MortgageAdvertLink from '@/modules/mortgage-calc/components/mortgage/item/MortgageAdvertLink'
  import MortgageInformation from '@/modules/mortgage-calc/components/mortgage/item/MortgageInformation'
  import MortgageDetailInformation from '@/modules/mortgage-calc/components/mortgage/item/MortgageDetailInformation'
  import { mapState } from 'vuex'

  const device = new MobileDetect(window.navigator.userAgent)

  export default {
    name: 'MortgageItem',
    components: {
      MortgageDetailInformation,
      MortgageInformation,
      MortgageAdvertLink,
      BankHeader,
      MobileDetect
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapState({
        isVisibleAdvert: 'isVisibleAdvert'
      }),
      isMobileDevice () {
        return !!(device.mobile() || device.tablet())
      },
      disabled () {
        return !this.data.active
      },
      isAdvertiser () {
        return this.data.advertiser && this.data.advert_url && this.isVisibleAdvert;
      }
    },
    data () {
      return {
        active: false,
      }
    },
    methods: {
      onClickProgram () {
        if (!this.isAdvertiser) {
          this.active = !this.active
        }
      }
    }
  }
</script>
