<script>
  import Quiz from '../quiz/App'

  // Components
  import PayMonthForm from './components/PayMonthForm'
  import SendFormComponent from './components/SendFormComponent'

  export default {
    mixins: [ Quiz ],
    components: {
      PayMonthForm,
      SendFormComponent
    },
    methods: {
      addCustomSteps() {
        this.$store.commit('addCustomSteps', [
          {
            name: 'payMonth',
            component: 'PayMonthForm',
            heading: 'Сколько вы готовы платить по кредиту в месяц?'
          },
          {
            name: 'finish',
            component: 'SendFormComponent'
          }
        ]);
      }
    }
  }
</script>