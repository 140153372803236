<template>
    <div class="b-item b-text-item">
        <slot name="label">
            <label :for="id" class="b-label-item">
                {{ label }}
            </label>
        </slot>

        <div class="b-item-box">
            <input
                :disabled="disabled === true"
                ref="field"
                :placeholder="placeholder"
                maxlength="14"
                :id="id"
                class="b-castom-text-item b-castom-field-item form__input"
                type="text"
                @keyup="change"
                @blur="save"
            />
          <div class="b-help-item"><span class="rub">{{ appendField }}</span></div>
          <div v-if="percentHandler" class="b-value-item">
            <span class="b-value-text-item">{{ priceFormat(value) }}</span>
            <div class="b-value-procent-item">{{ percentHandler() }}%</div>
          </div>
        </div>
        <div class="b-item-box b-item-box__vue-slider">
            
            <vue-slider
                v-if="optionsMerge.min !== optionsMerge.max"
                v-bind="optionsMerge"
                :value.sync="value"
                :silent="true"
                tooltip="none"
                :disabled="disabled"
                :duration="duration"
                @callback="changeSlider"
                @drag-end="dragStop"
            ></vue-slider>
            <div class="b-help-item">
            
            </div>
        </div>
    </div>
</template>

<script>
  import vueSlider from 'vue-slider-component'

  export default {
    components: {
      vueSlider
    },
    name: 'field',
    props: {
      options: {
        type: Object,
        required: true
      },
      label: String,
      placeholder: String,
      value: [Number, String],
      numberFormat: {
        type: Boolean,
        default: false
      },
      appendField: String,
      percent: Function,
      disabled: {
        type: Boolean,
        default: false
      },
      maxValue: Number,
      duration: {
        type: Number,
        default: 0.5
      },
      lazy: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      value(value, oldValue) {
        if (value !== oldValue && this.isChangeSlider) {
          this.$refs.field.value = this.priceFormat(value);
        } else {
          this.isChangeSlider = true;
        }
      },
      options(value) {
        this.optionsMerge = Object.assign({}, this.optionsMerge, value);
      }
    },
    created() {
      this.id = 'id-field-' + (Math.random() * 100000)
    },
    mounted() {
      this.changeSlider(this.value);
      this.sliderValue = this.value;
    },
    data() {
      return {
        id: null,
        numFormat: this.numberFormat || false,
        percentHandler: typeof this.percent === 'function' ? this.percent : null,
        isChangeSlider: false,
        optionsMerge: Object.assign({}, this.options, {
          height: 2,
          'dot-size': 20
        }),
        sliderValue: 0
      }
    },
    methods: {
      dragStop(value) {
        if (this.lazy) {
          this.input(value.getValue());
        }
      },

      input(value) {
        if (this.value !== value) {
          this.$emit('input', value);
          this.$emit('change', value);
        }
      },

      changeSlider(value) {
        this.isChangeSlider = true;
        this.$refs.field.value = this.priceFormat(value);

        if (!this.lazy) {
          this.input(value);
        }
      },

      priceFormat(price) {
        return this.numFormat === true
          ? Number(parseFloat(Math.round(price)).toFixed(1)).toLocaleString()
          : price;
      },

      change(event) {
        this.isChangeSlider = false;

        const maxValue = this.maxValue || this.options.max

        let value = parseFloat(event.target.value.replace(/\D+/g, '')) || 0;

        if (this.numFormat === true) {
          event.target.value = value !== ''
            ? Number(value.toFixed(1)).toLocaleString()
            : 0;
        }

        if (value > maxValue) {
          value = maxValue;
        }

        if (value < this.options.min) {
          value = this.options.min;
        }

        this.input(value);
      },

      save(event) {
        event.target.value = this.priceFormat(this.value);
      },
    }
  }
</script>
<style lang="scss" scoped>
.content__formWrapper {
  padding: 0 2px;
}

.quiz-mortgate-slider {
  .b-item-box {
    position: relative;
  }
  .b-help-item,
  .b-castom-arrow-item {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
    display: inline-block;
    vertical-align: top;
  }
}
</style>
