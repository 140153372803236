<script>
  export default {
    methods: {
      /**
       * Payment by month
       *
       * @param {Object[]} percentList
       * @param {Object} config
       * @param {Boolean} annuityPayment
       * @return {Number}
       */
      mortgageResult(percentList, config, annuityPayment = true) {
        const { payment } = this.mortgageResultDetail(percentList, config, annuityPayment);
        return payment;
      },

      /**
       * Payment detail
       *
       * @param {Object[]} percentList
       * @param {Object} config
       * @param {Boolean} annuityPayment
       * @return {Object}
       */
      mortgageResultDetail(percentList, config, annuityPayment = true) {
        let params = {...config};

        if (!this.isMultiPercent(percentList, params.period)) {
          const [ percentItem ] = percentList;
          const payment = this.mathMortgage(params.period, percentItem.credit_percent, params.sum);
          return {...this.detailMortgageDefault(percentItem, payment, params, 1, annuityPayment), type: 'default'};
        } else {
          let details = [];
          let fromPeriod = 0;
          let period = params.period;
          let sum = params.sum;
          let amount = 0;

          percentList.forEach(({ credit_percent, credit_month }) => {
            if (period && sum) {
              credit_percent = +credit_percent;
              credit_month = +credit_month;

              if (credit_percent === 0) {
                credit_percent = 0.0000001;
              }

              let sumMonth = this.mathMortgage(period, credit_percent, sum);
              let month = +credit_month;

              month = month > period ? period : month;

              let item = {
                ...this.detailMortgageDefault(
                  { credit_percent },
                  sumMonth,
                  {
                    ...params,
                    sum,
                    period: fromPeriod + month,
                    fullPeriod: params.period,
                    fullSum: params.sum
                  },
                  fromPeriod + 1,
                  annuityPayment
                ),
                period: month
              };

              details.push(item);

              if (annuityPayment) {
                amount = this.getDebtNew(sumMonth, credit_percent, params.period, fromPeriod + month);
              } else {
                amount = params.sum - item.totalSum;
              }

              fromPeriod += month;
              period -= month;
              sum = amount;
            }
          });

          const result = {
            type: 'multi'
          };

          details.forEach(({ payment, detail, totalSum, totalOverpayment, period }) => {
            result.detail = (result.detail || []).concat(detail);
            result.totalSum = (result.totalSum || 0) + totalSum;
            result.totalOverpayment = (result.totalOverpayment || 0) + totalOverpayment;
            result.payment = (result.payment || 0) + (payment * period);
          });

          result.payment = (result.payment || 0) / params.period;
          result.details = details;

          return result;
        }
      },

      /**
       * @param {Object} percentItem
       * @param {Number} payment
       * @param {Object} params
       * @param {Number} month
       * @param {Boolean} annuityPayment
       */
      detailMortgageDefault(percentItem, payment, params, month = 1, annuityPayment = true) {
        let detail = [];
        let prev = null;
        let param = {};
        let totalOverpayment = 0;
        let totalSum = 0;

        if (annuityPayment) {
          let percentMonth = parseFloat(percentItem.credit_percent) / 1200;

          while (month <= params.period) {
            param = {
              month,
              payMonth: payment
            };

            if (!prev) {
              param.percent = params.sum * percentMonth;
              param.sum = payment - param.percent;
              param.debt = params.sum - param.sum;
            } else {
              param.percent = prev.debt * percentMonth;
              param.sum = payment - param.percent;
              param.debt = prev.debt - param.sum;
            }

            totalOverpayment += param.percent;
            totalSum += param.sum;

            prev = {...param};

            detail.push(param);

            month++;
          }
        } else {
          let creditSum = params.sum / params.period;

          while (month <= params.period) {
            param = { month, sum: creditSum };

            param.percent = (prev ? prev.debt : params.sum) * parseFloat(percentItem.credit_percent) / (100 * 12);
            param.payMonth = param.sum + param.percent;
            param.debt = (prev ? prev.debt : params.sum) - creditSum;

            totalOverpayment += param.percent;
            totalSum += param.sum;

            prev = {...param};

            detail.push(param);

            month++;
          }
        }

        return { payment, detail, totalSum, totalOverpayment }
      },

      mathMortgage(period, percent, sum) {
        if (percent > 0) {
          let percentMonth = parseFloat(percent) / 1200;
          return (sum * percentMonth) / (1 - (Math.pow(1 + percentMonth, -period)));
        }

        return sum / period;
      },

      getPriceObject(period, percent, sum) {
        let percentMonth = parseFloat(percent) / 1200;
        let k = (percentMonth * (Math.pow((1 + percentMonth), period))) / (Math.pow((1 + percentMonth), period) - 1);
        return sum / k;
      },

      getDebtNew(monthSum, percent, allPeriod, period) {
        let percentMonth = (parseFloat(percent) / 100) / 12;
        let kof = 1 - Math.pow(1 + percentMonth, -(allPeriod - period));
        let kofSum = kof / percentMonth;
        let result = monthSum * kofSum;

        return Math.round(result);
      },

      /**
       * @return {Boolean}
       */
      isMultiPercent(percentList, period) {
        if (percentList.length === 1) {
          return false;
        }

        let [ first ] = percentList;

        if (+first.credit_month >= +period) {
          return false;
        }

        return true;
      },
    }
  }
</script>