<template>
  <Fragment>
    <a
      v-if="!showFrame"
      target="_blank"
      :href="urlFilter"
      :class="{ ['advmortgage_' + bankId]: true }"
      class="button-border-green b-listing-button b-button-out-link"
    >{{ tools.button_advert_text }}</a>

    <a
      v-else
      href="javascript:void(0)"
      @click="showModal"
      :class="{ ['advmortgage_' + bankId]: true }"
      class="button-border-green b-listing-button b-button-out-link"
    >{{ tools.button_advert_text }}</a>

    <div>
      <Modal v-model="show" size="modal-lg" class="modal-alfa-class">
        <ModalHeading>
          <div>
            <a class="modal-close" @click="show = false">
              <svg class="w_28">
                <use xlink:href="#close-24"></use>
              </svg>
            </a>
          </div>
        </ModalHeading>
        <ModalContent>
          <iframe
            v-if="show"
            :src="urlFilter"
            width="100%"
            height="1000px"
            frameborder="no"
            scrolling="no"
          />
        </ModalContent>
      </Modal>
    </div>
  </Fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment'
  import ModalContent from '@/modules/UI/modal/ModalContent'
  import Modal from '@/modules/UI/modal/Modal'
  import ModalHeading from '@/modules/quiz-landing/components/modal/ModalHeading'
  import { mapState } from 'vuex'

  export default {
    name: 'MortgageAdvertLink',
    components: {
      Fragment,
      ModalHeading,
      Modal,
      ModalContent
    },
    props: {
      url: {
        type: String,
        required: true
      },
      showFrame: {
        type: Boolean,
        default: false
      },
      bankId: {
        type: Number
      }
    },
    data: () => ({
      show: false
    }),
    computed: {
      ...mapState({
        params: 'params',
        tools: 'tools'
      }),
      urlFilter() {
        const params = {
          ...this.params,
          fee: Math.round(this.params.fee),
          periodYear: this.params.period,
          periodMonth: this.params.period * 12,
          feePercent: ((100 * this.params.fee) / this.params.sum).toFixed(2),
          price: this.params.sum
        }

        let url = this.url;

        for (const key in params) {
          url = url.replace(`{{${key}}}`, params[key]);
        }

        return url;
      }
    },
    methods: {
      showModal() {
        this.show = true;
      }
    }
  }
</script>
