<template>
    <form @submit="$event.preventDefault()">
        <loader v-if="loader" class="mt_0 mb_0 pt_5 pb_5" />

        <div v-else class="mb_20">
            <loader v-if="loaderSend" class="mt_0 mb_0 pt_5 pb_5" />

            <FieldSliderComponent
                class="quiz-mortgate-slider"
                v-if="value > 0"
                :options="prices"
                v-model="value"
                append-field="₽"
                :numberFormat="true"
            />

        </div>

        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <div class="content__buttonBox">
            <button
                    v-if="!isFirst"
                    :disabled="loader || loaderSend"
                    @click="$store.commit('prev')"
                    class="button_def button__size button__type_2"
            >Назад
            </button>

            <div class="growOn"></div>

            <button
                    :disabled="!isValid || loaderSend || loader"
                    @click="getResult"
                    class="button_def button__size button__type_1 text_md medium "
            >
                {{ isLast ? 'Показать результат' : 'Следующий вопрос' }}
            </button>
        </div>


    </form>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { TextHelper } from '@/helpers/TextHelper'
  import FieldSliderComponent from '../../mortgage-calc/components/FieldSliderComponent'
  import Loader from '../../mortgage-calc/components/Loader'

  export default {
    name: 'PayMonthForm',
    components: {
      FieldSliderComponent,
      Loader
    },
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst',
      }),
      ...mapState('mortgage', {
        prices: 'prices',
        loader: 'minPayMonthLoader'
      }),
      ...mapState({
        form: 'form',
        step: 'step',
        error: 'error',
        result: 'result',
        loaderSend: 'loaderSend'
      }),
      value: {
        get() {
          const key = this.formData.name;
          if (this.form && typeof this.form === 'object' && this.form.hasOwnProperty(key)) {
            return this.form[key];
          }
          return null;
        },
        set(value) {
          this.$store.commit('setForm', {...this.form, [this.formData.name]: value});
        }
      },
      isValid() {
        return this.value !== null;
      }
    },
    data: () => ({
      lastNamePrice: 'last'
    }),
    async created() {
      await this.load();
    },
    methods: {
      async load() {
        const { min } = await this.$store.dispatch('mortgage/getMinPayMonth', this.form);
        if (this.value === null || this.value < min) {
          this.value = min;
        }
      },

      async getResult() {
        await this.$store.dispatch('mortgage/getTotalWithNovos');
        if (this.result) {
          this.$store.commit('next');
        }
      },

      priceFormat(value) {
        return TextHelper.priceFormat(value);
      }
    }
  }
</script>
