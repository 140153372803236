<template>
  <div class="b-listing-col">
    <div class="row">

      <div class="col-xs-6 col-md-4 d_f fd_cr_xs fa_c_xs">
        <div class="br_all d_f a_i_c j_c_c mr_20 m-canvas-item">
          <Chart
            :data="chartData"
            :options="chartOptions"
            :width="92"
            :height="92"
          />
        </div>
      </div>

      <div class="col-xs-6 col-md-8" :class="{ 'is-hover-chart': activeIndex !== null }">
        <div class="rr-diagram-after">
          <div
            :class="{ 'is-active': activeIndex === 0 }"
            class="rr-diagram-after-title-item rr-green fs_16 lh_24 pos_rel d_f a_i_c"
          >
            <div class="pr_5">
              <div class="rr-diagram-point-item br_all w_12 v_centered left_0" />
              <div class="rr-diagram-name stroke_crop">Начальный взнос</div>
            </div>
            <div
              style="border-bottom: 1px dotted #bdbdbd; align-self: center;"
              class="f_g_1 lh_20 pt_10 hidden-xs"
            />
            <div class="rr-diagram-value">
              {{ fee }} <span class="rub">₽</span>
            </div>
          </div>
        </div>
        <div class="rr-diagram-after">
          <div
            :class="{ 'is-active': activeIndex === 1 }"
            class="rr-diagram-after-title-item rr-blue fs_16 lh_24 pos_rel d_f"
          >
            <div class="pr_5">
              <div class="rr-diagram-point-item br_all w_12 v_centered left_0" />
              <div class="rr-diagram-name stroke_crop">Сумма кредита</div>
            </div>
            <div
              style="border-bottom: 1px dotted #bdbdbd;transform: translate3d(0,4px,0);align-self: center;"
              class="f_g_1 lh_20 hidden-xs"
            />
            <div class="rr-diagram-value">
              {{ sum }} <span class="rub">₽</span>
            </div>
          </div>
        </div>
        <div class="rr-diagram-after">
          <div
            :class="{ 'is-active': activeIndex === 2 }"
            class="rr-diagram-after-title-item rr-orange fs_16 lh_24 pos_rel d_f a_i_c"
          >
            <div class="pr_5">
              <div class="rr-diagram-point-item br_all w_12 v_centered left_0" />
              <div class="rr-diagram-name stroke_crop">Переплата</div>
            </div>
            <div
              style="border-bottom: 1px dotted #bdbdbd;align-self: center;"
              class="f_g_1 lh_20 pt_10 hidden-xs"
            />
            <div class="rr-diagram-value">
              {{ overpayment }} <span class="rub">₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d_f fw_n_w_xs fj_rl_xs">
      <button
        v-if="rc"
        :data-mpn-type="mpnType"
        :class="{ ['callme' + rc]: true }"
        v-tooltip
        title="Получить консультацию ипотечного менеджера"
        class="button-border-green b-listing-button"
      >
        <svg fill="#17c500" class="w_20 callback_item callbeat_hover_item hidden-xs">
          <use xlink:href="#phone-24"></use>
        </svg>
        <div class="visible-xs data_color">
          Консультация
        </div>
      </button>

      <a
          v-if="moreBtnRc"
          data-mpn-type="ipoteka_sale"
          :class="{ ['callme' + moreBtnRc]: true }"
          class="b-listing-button stroke_crop m-link-item-about h_def_bgr h_def_blue h_def_red def_blue"
          href="#"
      >
        Подробнее
      </a>
      <a v-else
         target="_blank"
         class="b-listing-button stroke_crop m-link-item-about h_def_bgr h_def_blue h_def_red def_blue"
         :href="'/banki/' + data.alias + '/programs?mortgageId=' + data.mortgage_id + '&novosId=' + data.novos_id"
      >
        Подробнее
      </a>
    </div>
  </div>
</template>

<script>
  import Chart from '../ChartMortgage'
  import { mapState } from 'vuex'

  const defaultColorChart = [
    '#17C500',
    '#017EEC',
    '#FF9600'
  ]

  export default {
    name: 'MortgageDetailInformation',
    components: {
      Chart
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        activeIndex: null,
        chartColors: [...defaultColorChart],
        chartOptions: {
          fontSizeChart: 32,
          onHover: (bars, points) => {
            const [item] = points
            if (item) {
              this.activeIndex = item._index
            } else {
              this.activeIndex = null
            }
          },
          hover: {
            mode: 'index'
          },
          animation: {
            duration: 0,
            animateRotate: false,
          },
          responsive: false,
          legend: false,
          cutoutPercentage: 55,
          tooltips: {
            enabled: false,
          }
        }
      }
    },
    computed: {
      ...mapState({
        params: 'params',
        rc: 'rc',
        mpnType: 'mpnType',
        moreBtnRc: 'moreBtnRc'
      }),
      sum () {
        return Number(Math.ceil(+this.params.sum - +this.params.fee)).toLocaleString()
      },
      fee () {
        return Number(Math.ceil(this.params.fee)).toLocaleString()
      },
      total () {
        if (this.data.result) {
          return Number(this.data.result.replace(/\D+/, '')) * (this.params.period * 12)
        }

        return 0
      },
      overpaymentNumber () {
        return this.total === 0 ? 0 : Math.ceil(this.total - (this.params.sum - this.params.fee))
      },
      overpayment () {
        return Number(this.overpaymentNumber).toLocaleString()
      },
      chartData () {
        return {
          labels: ['Начальный взнос', 'Сумма кредита', 'Переплата'],
          datasets: [
            {
              data: [this.params.fee, this.params.sum, this.overpaymentNumber],
              backgroundColor: [...this.chartColors],
              hoverBackgroundColor: [...this.chartColors],
              hoverBorderColor: '#ffffff',
              hoverBorderWidth: 2,
              borderWidth: 2,
              weight: 1,
            }
          ]
        }
      },
    }
  }
</script>
