<template>
    <div class="b-box-listing-content mb_10">
        <div style="border: 1px solid #ffe400">
            <div class="d_f j_c_s_b a_i_c pl_20 pr_20 b-listing-col">
                <div class=" d_f a_i_c stroke_crop">
                    <div class="mr_16 ">
                        <img style="height: 32px;" :src="advert.logo" alt="">
                    </div>
                    <div class="fs_20 lh_28 fw_m stroke_crop">
                        {{ advert.name }}
                    </div>
                </div>
                <div class="d_f">
                    <div class="ml_10">
                        <svg width="20" height="20" fill="#ea3b38" class="w_20">
                            <use xlink:href="#hot-24"></use>
                        </svg>
                    </div>
                </div>
            </div>
            <div :style="{ backgroundColor: advert.color || '#fff8d5'}" class="b-listing-col pl_20 pr_20">
                <div class="fs_16 lh_24 mb_10">{{ advert.heading }}</div>
                <a target="_blank" class="fw_m" :href="advert.url">{{ advert.button }}</a>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Advert',
    props: {
      advert: {
        type: Object,
        default: () => { }
      }
    }
  }
</script>