<template>
  <div>
    <Loader v-if="loader"/>

    <div v-else-if="loader === false">

      <div class="d_f j_c_s_b f_d_c_md">
        <h2 class="card_h2 js-morgage-header">Ипотека</h2>

        <div v-if="isOptimalParams" class="plans_tab_box pb_10">
          <div v-if="isAdvertiser" class="plans_tab_item va_t" :class="{ active: onlyAdvertiser }">
            <a class="pr_8" href="javascript:;" @click="setOnlyAdvertiser">
              Специальные условия
            </a>
          </div>
          <div class="plans_tab_item va_t" :class="{ active: !isMinMonth && !isPreferentialMortgage && !onlyAdvertiser }">
            <a class="pr_8" href="javascript:;" @click="offMinMonth">
              Все программы
            </a>
          </div>
          <div
            v-if="hasFilterPreferentialMortgage && !isAdvertiser"
            class="plans_tab_item va_t"
            :class="{ active: isPreferentialMortgage && !isMinMonth }"
          >
            <a href="javascript:;" @click="setPreferentialMortgage">
              Льготная ипотека
            </a>
          </div>
          <div v-if="!isAdvertiser" class="plans_tab_item va_t" :class="{ active: isMinMonth && !isPreferentialMortgage }">
            <a href="javascript:;" @click="onMinMonth">
              Оптимальные условия
            </a>
          </div>
        </div>
      </div>

      <Item
        v-for="(mortgage, key) in dataSortable"
        :key="mortgage.id"
        :data="mortgage"
        v-if="visibleItems > key"
      />

      <div v-if="isMinMonth === false && visibleItems < dataSortable.length && dataSortable.length > 0" class="mb_20 mt_20">
        <div @click="showAllItems" class="m-btn-more b-listing-show-item c_p d_f a_i_c j_c_c">
          {{ renderTextMoreAll(data) }}
        </div>
      </div>

      <div v-if="isMinMonth === true && dataSortable.length > 0" class="b-listing-body-item">
        <div @click="offMinMonth" class="b-listing-row-item b-listing-show-item b-title-box b-listing-col">
          Показать все предложения
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TextHelper } from '../../helpers'
import MortgageCalcMixin from './MortgageCalcMixin'
import Advert from './AdvertComponent'
import Item from './item/MortgageItemComponent'
import AdvertItem from './AdvertBankItemComponent'
import Loader from '../Loader'
import { mapState, mapGetters } from 'vuex'

export default {
  components: { Advert, Loader, Item, AdvertItem },
  mixins: [MortgageCalcMixin],
  name: 'BankList',
  computed: {
    ...mapState({
      houseItem: 'houseItem',
      isOptimalParams: 'isOptimalParams',
      pageItems: 'pageItems',
      params: 'params',
      adverts: 'adverts',
      data: 'bankList',
      loader: 'bankListLoader',
      hasFilterPreferentialMortgage: 'hasFilterPreferentialMortgage',
      preferentialMortgageName: 'preferentialMortgageName',
      isPreferentialMortgage: 'isPreferentialMortgage',
      showOnlyAdvertiser: 'showOnlyAdvertiser',
      isVisibleAdvert: 'isVisibleAdvert',
    }),
    ...mapGetters({
      isAdvertiser: 'isAdvertiser',
      bankList: 'bankList'
    }),
    onlyAdvertiser() {
      return this.showOnlyAdvertiser && this.isAdvertiser;
    },
    dataSortable() {
      return this.bankList;
    }
  },
  data () {
    return {
      copyData: [],
      copyParams: {},
      visibleItems: 0,
      isMinMonth: false
    }
  },
  created () {
    this.visibleItems = this.pageItems
  },
  async mounted () {
    await this.getList()
    this.handleChangedParams();

    if (this.isAdvertiser) {
      this.setOnlyAdvertiser();
    }
  },
  watch: {
    houseItem () {
      this.getList()
    },
    params () {
      this.handleChangedParams()
    }
  },
  methods: {
    handleChangedParams () {
      this.$store.commit('setBankList', this.data.map(item => this.calc(item)))
    },

    calc (mortgage) {
      let item = {...mortgage};

      item.periods = this.changePercent(item);

      const params = { ...this.params, sum: this.params.sum - this.params.fee, period: this.params.period * 12 }
      const active = this.isActiveOffer(item)

      const isAnnuityPayment = item.hasOwnProperty('isAnnuityPayment') ? item.isAnnuityPayment : true

      const result = active === true
        ? Number(Math.round(this.mortgageResult(item.periods, params, isAnnuityPayment))).toLocaleString()
        : 0

      return { ...item, active, result }
    },

    changePercent(mortgage) {
      if (mortgage.conditions.length > 0) {
        const mapParams = {
          COST_ESTATE: this.params.sum,
          BODY_CREDIT: this.params.sum - this.params.fee,
          FEE: Math.round((this.params.fee * 100) / this.params.sum),
          CREDIT_TERM: this.params.period
        };

        return mortgage.periods.map(percent => {
          if (percent.hasOwnProperty('can_change_percent') && +percent.can_change_percent < 1) {
            return percent;
          }

          if (!percent.hasOwnProperty('defaultCreditPercent')) {
            percent.defaultCreditPercent = parseFloat(percent.credit_percent);
          }

          let defaultPercent = percent.defaultCreditPercent;

          mortgage.conditions.forEach(condition => {
            if (mapParams.hasOwnProperty(condition.param_name)) {
              const value = mapParams[condition.param_name];
              const { value_from, value_to, change_percent } = condition;

              if (value >= +value_from && (!value_to || (value_to > 0 && value <= +value_to))) {
                defaultPercent += parseFloat(change_percent);
              }
            }
          });

          return {
            ...percent,
            credit_percent: parseFloat(defaultPercent.toFixed(2))
          };
        });
      }

      return mortgage.periods;
    },

    isActiveOffer (item) {
      const { period, sum, fee } = this.params;
      const bodyCredit = sum - fee;
      const creditTermMonth = period * 12;
      const feePercent = Math.round((fee * 100) / sum);

      const {
        credit_month: maxCreditTerm,
        min_fee: minFeePercent,
        body_credit_max: maxBodyCredit,
        body_credit_min: minBodyCredit,
        cost_estate_max: maxCostEstate,
        cost_estate_min: minCostEstate,
        credit_term_min: minCreditTerm
      } = item;

      item.errors = [];

      if (creditTermMonth > +maxCreditTerm) {
        const years = Math.ceil(maxCreditTerm / 12);
        item.errors.push(`Максимальный срок кредита ${years} ${TextHelper.declensionWords(years, [
          'год', 'года', 'лет'
        ])}`);
        item.errors.push(`Уменьшите срок кредита`);
        return false;
      }

      if (minCreditTerm > 0 && creditTermMonth < minCreditTerm) {
        const years = Math.ceil(minCreditTerm / 12);
        item.errors.push(`Минимальный срок кредита ${years} ${TextHelper.declensionWords(years, [
          'год', 'года', 'лет'
        ])}`);
        item.errors.push(`Увеличьте срок кредита`);
        return false;
      }

      if (feePercent < minFeePercent) {
        const minFeeSum = Math.ceil(sum * (minFeePercent / 100));
        item.errors.push(`Необходимо увеличить первоначальный взнос до ${TextHelper.priceFormat(minFeeSum)} руб.`);
        return false;
      }

      if (maxBodyCredit > 0 && bodyCredit > maxBodyCredit) {
        item.errors.push(`Сумма кредита не может быть больше ${TextHelper.priceFormat(maxBodyCredit)} руб.`);
        return false;
      }

      if (minBodyCredit > 0 && bodyCredit < minBodyCredit) {
        item.errors.push(`Сумма кредита не может быть меньше ${TextHelper.priceFormat(minBodyCredit)} руб.`);
        return false;
      }

      if (maxCostEstate > 0 && sum > maxCostEstate) {
        item.errors.push(`Стоимость недвижимости не может быть больше ${TextHelper.priceFormat(maxCostEstate)} руб.`);
        return false;
      }

      if (minCostEstate > 0 && sum < minCostEstate) {
        item.errors.push(`Стоимость недвижимости не может быть меньше ${TextHelper.priceFormat(minCostEstate)} руб.`);
        return false;
      }

      return !item.errors.length;
    },

    showAllItems () {
      this.visibleItems = this.data.length
    },

    async getList () {
      await this.$store.dispatch('getBanks')
    },

    renderTextMoreAll (data) {
      let itemsBank = data.length - this.pageItems
      // let textBank = TextHelper.declensionWords(itemsBank, ['банк', 'банка', 'банков'])
      let textBank = TextHelper.declensionWords(itemsBank, ['предложение', 'предложения', 'предложений'])
      return `Ещё ${itemsBank} ${textBank}`
    },

    renderTextPeriods (mortgageItem) {
      let text = `Ставка ${parseFloat(mortgageItem[0].credit_percent)}% на первые ${mortgageItem[0].credit_month} `
      text += TextHelper.declensionWords(mortgageItem[0].credit_month, ['месяц', 'месяца', 'месяцев'])
      text += `, далее ${parseFloat(mortgageItem[1].credit_percent)}%`

      return text
    },

    onMinMonth () {
      this.offMinMonth()

      this.$nextTick(() => {
        this.$store.commit('setParams', { tags: [] });

        let programms = this.data.sort((a, b) => {
          if (a.month_min_novos_summ > b.month_min_novos_summ) {
            return 1;
          } else if (a.month_min_novos_summ < b.month_min_novos_summ) {
            return -1;
          } else {
            return 0;
          }
        }).filter(({ active }) => active).slice(0, 3);

        if (programms.length) {
          let first = programms[0];

          this.isMinMonth = true

          this.copyData = [...this.data]
          this.copyParams = { ...this.params }

          this.$store.commit('setParams', {
            sum: this.params.sum,
            fee: this.params.sum * (first.min_fee / 100),
            period: +first.credit_month / 12
          });

          this.$store.commit('setBankList', programms);
        }
      })
    },

    setPreferentialMortgage () {
      this.offMinMonth()

      this.$nextTick(() => {
        this.copyData = [...this.data]
        this.$store.commit('setPreferentialMortgage', true)
        this.$store.commit('setBankList', this.data.filter(({ tags }) =>
          tags.some(({ name }) => name === this.preferentialMortgageName)
        ));
      })
    },

    offMinMonth () {
      if (this.copyData.length) {
        this.$store.commit('setParams', { tags: [] });

        this.isMinMonth = false;
        this.$store.commit('setShowOnlyAdvertiser', false);
        this.$store.commit('setPreferentialMortgage', false)
        this.$store.commit('setParams', { ...this.copyParams })
        this.$store.commit('setBankList', [...this.copyData])
        this.copyData = []
        this.copyParams = {}
      }
    },

    setOnlyAdvertiser() {
      this.$store.commit('setParams', { tags: [] });
      this.$store.commit('setShowOnlyAdvertiser', true);

      this.$nextTick(() => {
        this.copyData = [...this.data]
        this.$store.commit('setBankList', this.data.filter(({ advertiser }) => +advertiser === 1))
      });
    }
  }
}
</script>
