<template>
  <div class="content__container">
    <div class="quiz-left-colum" :class="'step-'+ stat.level">
      <img v-show="item == stat.level" v-for="item in stat.levels" style="display: none" :src="`/images/quiz/bg-step-${item}.png`" alt="">
    </div>
    <div v-if="formData && stat.level" class="content__content pRel">
        <div class="content__wrapper heightAll">
            <div v-if="stat.level" class="content__progressBox">
                <div class="pRel">
                    <div class="medium text_md progress__text layout pAbs">{{ stat.level }}/{{ stat.levels }}</div>
                    <svg viewBox="0 0 32 32" class="progress__container">
                        <circle class="progress__circleEmpty" r="16" cx="16" cy="16"></circle>
                        <circle class="progress__circleFull" r="16" cx="16" cy="16" :stroke-dasharray="progressBar(stat.progress)" ></circle>
                    </svg>
                </div>
            </div>

            <div class="content__form">
                <div class="content__formWrapper py-1">
                    <div v-if="formData.heading" class="form__title title__form bold">{{ formData.heading }}</div>

                    <div class="content__formContainer">
                        <template v-if="formData.fields">
                            <dynamic-form/>
                        </template>
                        <template v-else-if="formData.component">
                            <component :is="formData.component"/>
                        </template>
                    </div>
                </div>
            </div>

            <div class="content__footerMobile">
                <div class="text_xs">
                    Консультируем бесплатно:
                    <a :href="`tel:${phoneConsultation}`" class="color_blue">{{ phoneConsultation }}</a></div>
            </div>
        </div>
    </div>
    <div v-else-if="formData" class="listing__layoutAll growOn pRel">
        <component :is="formData.component"/>
    </div>
  </div>
</template>

<script>
  import Quiz from '../quiz/App'

  // Components
  import PayMonthForm from '@/modules/quiz-mortgage-calc/components/PayMonthForm'
  import SendFormComponent from '@/modules/quiz-mortgage-calc/components/SendFormComponent'
  import DynamicForm from '@/modules/quiz-landing/components/DynamicFormComponent'

  export default {
    mixins: [ Quiz ],
    components: {
      PayMonthForm,
      SendFormComponent,
      DynamicForm
    },
    methods: {
      addCustomSteps() {
        this.$store.commit('addCustomSteps', [
          {
            name: 'payMonth',
            component: 'PayMonthForm',
            heading: 'Сколько вы готовы платить по кредиту в месяц?'
          },
          {
            name: 'finish',
            component: 'SendFormComponent'
          }
        ]);
      },
      progressBar (val) {
        return val + ' 100';
      }
    },
    computed:{
      phoneConsultation () {
        return document.body.getAttribute('data-project-name') === 'msk' ? '+7 (495) 147-54-35' : '8-800-333-15-33'
      },
    },
  }
</script>
<style>

.quiz-mortgate-slider .b-castom-arrow-item, .quiz-mortgate-slider .b-help-item {
  position: relative;
  right: 0;
  top: 0;
  transform: none;
  display: inline-block;
  vertical-align: top;
}

.form__input {
  border: none;
  border-bottom: 1px solid #59AB4E;
  font-size: 20px;
  margin-bottom: 24px;
  outline: none;
}
.quiz-mortgate-slider .b-item-box {
  position: relative;
}
</style>
