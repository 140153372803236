<template>
  <div class="b-listing-grid-item">
    <div class="b-listing-area-name">
      <div class="b-label">Программа</div>
      <div class="b-value">
        <div v-tooltip :title="data.name" class="b-item stroke_crop fw_m">
          {{ data.name }}
        </div>
      </div>
    </div>

    <div class="b-listing-area-percent">
      <div class="b-label">Ставка</div>
      <div class="b-value">
        <div class="b-item">
          {{ minPercent }}
          <TooltipPercent v-if="data.periods.length > 1" :item="data" />
        </div>
      </div>
    </div>

    <div class="b-listing-area-minfee">
      <div class="b-label">Нач. взнос</div>
      <div class="b-value">
        от {{ parseFloat(data.min_fee) }}%
      </div>
    </div>

    <div class="b-listing-area-creditmonth">
      <div class="b-label">Срок кредита</div>
      <div class="b-value">
        до {{ Math.ceil(data.credit_month / 12) }} лет
      </div>
    </div>

    <div class="b-listing-area-result">
      <div class="b-label">Платеж в месяц</div>
      <div class="b-value fw_m">
        {{ data.result !== 0 ? data.result + ' ₽' : '—' }}
      </div>
    </div>
  </div>
</template>

<script>
  import TooltipPercent from '@/modules/mortgage-calc/components/mortgage/TooltipPercent'

  export default {
    name: 'MortgageInformation',
    components: {
      TooltipPercent
    },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    computed: {
      minPercent () {
        return parseFloat(this.data.periods[0].credit_percent || 0)
      },
    }
  }
</script>
