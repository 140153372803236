<template>
  <div class="mb_10" :style="cssVars" :class="'theme-' + bank.alias">
    <div v-if="!showOnlyAdvertiser && isPreferentialMortgage" class="visible-xs">
      <div class="pt_4 pb_4 pl_8 pr_4 map_lgray f_w_nw d_f def_lblack a_i_c m-badge-spec pos_rel">
        <div class="mr_8 fs_12 lh_16 fw_m stroke_crop f_g_1">Льготная ипотека</div>
        <div v-popover="{
          trigger: 'hover',
          placement: 'top',
          content: getPopoverContent(),
          html: true,
        }" class="w_16 d_f a_i_c">
          <svg fill="rgba(66, 66, 66, 0.5)" class="w_16 va_m op_5">
            <use xlink:href="#help-24"></use>
          </svg>
        </div>
      </div>
    </div>
    <div class="bank-advertiser">
      <div class="d_f j_c_s_b a_i_c pl_20 pr_20 b-listing-col">
        <div class=" d_f a_i_c stroke_crop">
          <div class="mr_16">
            <img
              @error="notFound"
              style="height: 32px;"
              :src="bank.smallLogo"
              :alt="bank.bank_name"
            />
          </div>
          <div class="fs_20 lh_28 fw_m stroke_crop">
            <div>{{ bank.bank_name }}</div>
            <div class="fs_10 lh_12 name_color" style="line-height: 12px;">
              Лиц. № {{ bank.license }}
            </div>
          </div>
        </div>
        <div v-if="!showOnlyAdvertiser" class="d_f a_i_c">
          <div v-if="top" v-bs-tooltip="top.title" class="ml_10">
            <svg :fill="top.color" width="20" height="20" class="w_20">
              <use xlink:href="#cup-24"></use>
            </svg>
          </div>

          <div v-if="isPreferentialMortgage" class="hidden-xs ml_10 pt_4 pb_4 pl_8 pr_4 map_lgray d_f br_4 def_lblack a_i_c">
            <div class="mr_8 fs_12 lh_16 fw_m">Льготная ипотека</div>
            <div
              v-popover="{
                trigger: 'hover',
                placement: 'top',
                content: getPopoverContent(),
                html: true,
              }"
              class="w_16 d_f a_i_c"
            >
              <svg fill="rgba(66, 66, 66, 0.5)" class="w_16 va_m op_5">
                <use xlink:href="#help-24"></use>
              </svg>
            </div>
          </div>

        </div>
      </div>

      <div class="bgr_fafafa pl_20 pr_20 bank-advertiser-border hidden-xs">
        <div class=" b-box-1-header">
          <div class="b-listing-head-item visible-md visible-sm visible-lg">
            <div class="b-listing-row-item">
              <div class="row">
                <div class="col-sm-4 b-listing-col">
                  <div class="b-label-item">Программа</div>
                </div>
                <div class="col-sm-4 col-xs-6 b-listing-col">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="b-label-item">Ставка</div>
                    </div>
                    <div class="col-sm-6">
                      <div class="b-label-item">Нач. взнос</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-xs-6 b-listing-col">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="b-label-item">Срок кредита</div>
                    </div>
                    <div class="col-sm-6">
                      <div class="b-label-item b-last-col">Платеж в месяц</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdvertMortgageItem
        v-for="data in mortgageList"
        :key="data.id"
        :data="data"
      />

      <AdvertBankItemAction
        v-if="active"
        :url="url"
        :isFrame="bank.show_frame > 0"
        :name="bank.bank_name"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import AdvertBankItemAction from '@/modules/mortgage-calc/components/mortgage/AdvertBankItemAction'
  import AdvertMortgageItem from '@/modules/mortgage-calc/components/mortgage/AdvertMortgageItem'
  import BankItemComponent, { topList } from '@/modules/mortgage-calc/components/mortgage/BankItemComponent'

  export default {
    name: 'AdvertBankItem',
    mixins: [BankItemComponent],
    components: {
      AdvertMortgageItem,
      AdvertBankItemAction
    },
    computed: {
      ...mapState({
        params: 'params',
        showOnlyAdvertiser: 'showOnlyAdvertiser',
        build: 'novosItem',
      }),
      url() {
        const params = {
          ...this.params,
          fee: Math.round(this.params.fee),
          periodYear: this.params.period,
          periodMonth: this.params.period * 12,
          feePercent: ((100 * this.params.fee) / this.params.sum).toFixed(2),
          price: this.params.sum
        }

        let url = this.bank.advert_url;

        const mortgageList = this.mortgageList.filter(({ active }) => active)
          .sort((a, b) => a.result > b.result ? 1 : (a.result < b.result ? -1 : 0));

        if (mortgageList.length) {
          url = mortgageList[0].advert_url;
        }

        for (const key in params) {
          url = url.replace(`{{${key}}}`, params[key]);
        }

        return url;
      },
      cssVars() {
        return {
          [`--${this.bank.alias}-color`]: this.active ? this.bank.brand_color : 'rgb(224, 224, 224)'
        };
      },
      top() {
        if (this.index in topList) {
          let item = topList[this.index];
          item.title = item.title.replace('{name}', this.build.name_pred);
          return topList[this.index];
        }
        return null;
      },
      active() {
        return this.mortgageList.some(({ active }) => active);
      }
    },
  }
</script>