<template>
    <fragment>
        <SendFormModal v-show="isShowModal" v-model="isShowModal">
            <div slot="heading" v-html="renderModalHeading" class="text_slg bold modal__title"/>
            <div slot="content">

                <form v-if="!success" novalidate @submit.prevent="checkForm" class="modal__formBox">
                    <div class="modal__formContainer">

                        <div class="modal__formWrapper" :class="{ 'has-error': errors.has('username') }">
                            <input
                                    type="text"
                                    v-model="username"
                                    name="username"
                                    v-validate="'min:2|max:50'"
                                    class="modal__form text_md"
                                    placeholder="Как вас зовут?"
                            />
                            <div v-if="errors.has('username')" class="modal__formError text_xs">
                                Имя должно содержать от 2 до 50 символов
                            </div>
                        </div>

                        <div class="modal__formWrapper" :class="{ 'has-error': errors.has('phone') }">
                            <input
                                    v-model="phone"
                                    name="phone"
                                    type="text"
                                    v-phone
                                    maxlength="18"
                                    v-validate="{ required: true, regex: phoneRegex }"
                                    class="modal__form text_md"
                                    placeholder="Номер телефона*"
                                    autocomplete="off"
                                    @keyup="eventLastLetter"
                            />
                            <div v-if="errors.has('phone')" class="modal__formError text_xs">
                                Необходимо заполнить поле «Телефон» в формате +7 (xxx) xxx-xx-xx
                            </div>
                        </div>

                        <div class="modal__formWrapper" :class="{ 'has-error': errors.has('email') }">
                            <input
                                    type="email"
                                    name="email"
                                    v-model="email"
                                    v-validate="'email'"
                                    class="modal__form text_md"
                                    placeholder="Электронная почта"
                                    @blur="eventBlurEmail"
                            />
                            <div v-if="errors.has('email')" class="modal__formError text_xs">
                                Введите корректно email
                            </div>
                        </div>

                    </div>

                    <div v-if="error" class="alert alert-danger" v-html="error"></div>

                    <!-- div class="opacity_5 text_xs modal__formAgree">
                        Нажимая на кнопку, Вы даете согласие на обработку своих <a class="" href="/soglashenie" target="_blank">персональных
                        данных</a>.
                    </div -->
                    <button
                            :disabled="loader"
                            type="submit"
                            class="button_def button__size button__type_1 text_md medium"
                    >
                        Отправить
                    </button>
                </form>
            </div>
        </SendFormModal>

        <div class="pRel heightAll listing__layoutPart">
            <div class="listing__complete pAbs">
                <img width="24" src="/images/quiz/icon-complete.svg" alt="">
            </div>
            <div class="flex-wrapper flexWrap">
                <div class="text_slg bold listing__title" v-html="renderHeading"/>
                <button v-if="success" class="button_def button__size button__type_1 button__again text_md medium" @click="goHome()">Начать заново</button>
            </div>

            <BuildList :list="result.novos" />

            <div class="listing__gradient"></div>

            <div class="listing__footerMobile">
                <div class="text_xs">
                    Консультируем бесплатно:
                    <a :href="`tel:${phoneConsultation}`" class="color_blue">{{ phoneConsultation }}</a></div>
            </div>
        </div>

    </fragment>
</template>

<script>
  import { TextHelper } from '@/helpers/TextHelper'
  import SendFormComponent from '@/modules/quiz-mortgage/components/SendFormComponent'
  import SendFormModal from '@/modules/quiz-landing/components/SendFormModal'
  import BuildList from '@/modules/quiz-landing/components/BuildListComponent'
  import { Fragment } from 'vue-fragment'

  export default {
    name: 'send-form',
    components: { SendFormModal, BuildList, Fragment },
    mixins: [SendFormComponent],
    data: () => ({
      isShowModal: false,
    }),
    mounted () {
      // modal will be showing in 2 sec
      setTimeout(() => this.isShowModal = true, 2000);

      var id = document.querySelector('[ya-counter]');

      if (id) {
        var yaId = id.getAttribute('ya-counter');

        if (window.hasOwnProperty('yaCounter' + yaId)) {
          window['yaCounter' + yaId].reachGoal('quiz_request');
        }
      }

    },
    methods: {
      triggerEvent() { }
    },
    computed: {
      renderHeading () {
        const build = TextHelper.declensionWords(this.result.counts.builds, ['новостройка', 'новостройки', 'новостроек'])

        return `По вашему запросу найдено <span class="poll-decor-badge"><span class="poll-decor-badgeQ">${this.result.counts.builds}</span> ${build}. Результаты поиска:`

      },
      renderModalHeading () {
        if (this.success) {
          this.isShowModal = false;
        }

        return !this.success
          ? `Оставьте свои контакты, чтобы увидеть все результаты и получить их на почту`
          : 'Спасибо, вы получите результаты на указанную электронную почту совсем скоро! У вас есть возможность изменить параметры и сделать новый подбор.'
      },
      phoneConsultation () {
        return document.body.getAttribute('data-project-name') === 'msk' ? '+7 (495) 147-54-35' : '8-800-333-15-33'
      },
    },
  }
</script>
