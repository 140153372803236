<template>
  <div class="b-bank stroke_crop">
    <div class="b-bank-logo">
      <img @error="notFound" :src="item.smallLogo" :alt="item.bank_name">
    </div>
    <div class="stroke_crop">
      <span class="b-bank-name stroke_crop">{{ item.bank_name }}</span>
      <!--<div style="line-height: 12px;" v-if="bank.license" class="fs_10 lh_12 name_color">
        Лиц. № {{ item.license }}
      </div>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: 'BankHeader',
    props: {
      item: {
        type: Object,
        required: true
      }
    },
    methods: {
      notFound (e) {
        e.target.src = '/images/banks/blank-logo-mini.svg'
      },
    }
  }
</script>

<style scoped>

</style>
