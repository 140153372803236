<script>
  import { Doughnut } from 'vue-chartjs'

  export default {
    extends: Doughnut,
    props: ['data', 'options'],
    watch: {
      data(value) {
        this.renderChart(value, this.options);
      }
    },
    mounted () {
      this.renderChart(this.data, this.options);
    }
  }
</script>