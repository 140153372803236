<template>
    <div class="b-listing-grid-item" :class="{ 'b-listing-disabled': !item.active }">
      <div class="b-listing-area-name">
        <div class="b-label">Программа</div>
        <div class="b-value">
          <div class="b-item ">
            <span class="b-title-name-item fw_m" :class="{ active }">
              {{ item.name }}
            </span>
            <span
                v-if="!active"
                @click="setActive"
                class="b-title-show-item">ещё</span>
          </div>
        </div>
      </div>

      <div class="b-listing-area-percent">
        <div class="b-label">Ставка</div>
        <div class="b-value">
          <div class="b-item">{{ parseFloat(item.price) }}%</div>
        </div>
      </div>

      <div class="b-listing-area-minfee">
        <div class="b-label">Нач. взнос</div>
        <div class="b-value">{{ parseFloat(item.min_first) }}%</div>
      </div>

      <div class="b-listing-area-creditmonth">
        <div class="b-label">Срок кредита</div>
        <div class="b-value">{{ item.month || (params.period * 12) }} мес.</div>
      </div>

      <div class="b-listing-area-result">
        <div class="b-label">Платеж в месяц</div>
        <div class="b-value fw_m">{{ item.result !== 0 ? item.result + ' ₽' : '—' }}</div>
      </div>

    </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'PaymentItem',
    props: {
      data: Object,
    },
    computed: {
      ...mapState({
        params: 'params'
      }),
      item() {
        return this.calc({...this.data});
      }
    },
    data() {
      return {
        active: false,
      }
    },
    methods: {
      setActive() {
        this.active = true;
      },

      calc(item) {
        item.active = this.isActiveOffer(item);
        item.result = item.active ? this.paymentResult(item, this.params) : 0;
        return item;
      },

      isActiveOffer(item) {
        const percent = parseFloat(Math.round((this.params.fee * 100) / this.params.sum));
        return percent >= parseFloat(item.min_first);
      },

      paymentResult(item) {
        let params = {...this.params};

        params.sum -= params.fee;
        params.period = parseInt(item.month) || (this.params.period * 12);

        let percent = parseFloat(item.price);
        let result = 0;

        if (percent > 0) {
          let kof = (percent / 12) / 100;
          let k = (kof * Math.pow(1 + kof, parseInt(params.period))) / (Math.pow(1 + kof, parseInt(params.period)) - 1);

          result = (k * Math.round(params.sum)).toFixed(0);
        } else {
          result = Math.round(params.sum / params.period);
        }

        return Number(result).toLocaleString()
      },
    }
  }
</script>