<template>
  <div class="op_6 op_hover w_24 va_t p_4 c_p" v-bs-tooltip.top="renderTextPeriods">
    <svg fill="#ea3b38" class="w_16 va_t">
      <use xlink:href="#change-24"/>
    </svg>
  </div>
</template>

<script>
  import { TextHelper } from '@/modules/mortgage-calc/helpers'

  export default {
    name: "TooltipPercent",
    props: {
      item: Object
    },
    computed: {
      renderTextPeriods() {
        const { periods: [first, second] } = this.item;

        return `
          Ставка ${parseFloat(first.credit_percent)}% на первые ${first.credit_month}
          ${TextHelper.declensionWords(first.credit_month, ['месяц', 'месяца', 'месяцев'])},
          далее ${parseFloat(second.credit_percent)}%
        `;
      },
    }
  }
</script>