<template>
  <Fragment>
    <div class="pl_20 pr_20 bgr_fafafa b-listing-row-item">
      <div class="b-listing-body-item">
        <div class="d_f pt_16 pb_16 bank-advertiser-wrapper">
          <div class="bank-advertiser-forUsers d_f">
            <img src="/images/banks/bank-smile.png" alt="">
            <div class="bank-advertiser-text fs_16 lh_24 ml_10">
              Только для посетителей {{ prName }}
            </div>
          </div>
          <a v-if="!isFrame" :href="url" target="_blank" class="bank-advertiser-add fs_16 lh_24">
            Отправить заявку
          </a>
          <a v-else href="javascript:void(0)" @click="showModal" class="bank-advertiser-add fs_16 lh_24">
            Отправить заявку
          </a>
        </div>
      </div>
    </div>

    <div>
      <Modal v-model="show" size="modal-lg"  class="modal-alfa-class">
        <ModalHeading>
          <div>
            <a class="modal-close" @click="show = false">
              <svg class="w_28">
                <use xlink:href="#close-24"></use>
              </svg>
            </a>
          </div>
        </ModalHeading>
        <ModalContent>
          <iframe
            v-if="show"
            :src="url"
            width="100%"
            height="1000px"
            frameborder="no"
            scrolling="no"
          />
        </ModalContent>
      </Modal>
    </div>
  </Fragment>
</template>

<script>
  import { Fragment } from 'vue-fragment'
  import ModalContent from '@/modules/UI/modal/ModalContent'
  import Modal from '@/modules/UI/modal/Modal'
  import ModalHeading from '@/modules/quiz-landing/components/modal/ModalHeading'

  export default {
    name: 'AdvertBankItemAction',
    components: { ModalHeading, Modal, ModalContent, Fragment },
    props: {
      url: {
        type: String,
        required: true
      },
      isFrame: {
        type: Boolean,
        default: false
      },
      name: String,
    },
    data: () => ({
      show: false,
    }),
    computed: {
      prName() {
        return document.body.getAttribute('data-project-name') === 'msk' ? 'Новострой-М' : 'Новострой-СПБ'
      }
    },
    methods: {
      showModal() {
        this.show = true;
      }
    }
  }
</script>