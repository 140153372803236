<template>
  <div class="pl_20 pr_20 bgr_fafafa b-listing-row-item" :class="{ active, 'b-listing-disabled': disabled }">
    <div class="b-listing-body-item">
      <div class="row c_p">
        <div class="col-sm-4">
          <div class="b-title-box b-listing-col b-listing-indent-col d_f">
            <div :title="data.name" v-tooltip class="stroke_crop fw_m">
              {{ data.name }}
            </div>
          </div>
        </div>
        <div class="col-sm-4 b-listing-col b-listing-indent-col">
          <div class="row">
            <div class="col-xs-6">
              <div class="b-listing-inner-col">
                <div class="b-label-item visible-xs">Ставка</div>
                <div class="b-title-box">
                  <div class="b-title-item">
                    {{ minPercent }}% <TooltipPercent v-if="item.periods.length > 1" :item="item" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="b-listing-inner-col">
                <div class="b-label-item visible-xs">Нач. взнос</div>
                <div class="b-title-box">
                  <div class="b-title-item">
                    от {{ parseFloat(item.min_fee) }}%
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4 b-listing-col">
          <div class="row">
            <div class="col-xs-6">
              <div class="b-listing-inner-col">
                <div class="b-label-item visible-xs">Срок кредита</div>
                <div class="b-title-box">
                  <div class="b-title-item">
                    до {{ Math.ceil(item.credit_month / 12) }} лет
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-6">
              <div class="b-listing-inner-col">
                <div class="b-label-item visible-xs">Платеж в месяц</div>
                <div class="b-title-box b-title-active-box">
                  <div class="b-title-item b-last-col">
                    {{ item.result !== 0 ? item.result + ' ₽' : '—' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MortgageItem from '@/modules/mortgage-calc/components/mortgage/item/MortgageItemComponent'
  import TooltipPercent from '@/modules/mortgage-calc/components/mortgage/TooltipPercent'

  export default {
    name: 'AdvertMortgageItem',
    components: { TooltipPercent },
    mixins: [ MortgageItem ]
  }
</script>
