<template>
    <div>
       <!-- <div v-if="isPreferentialMortgage" class="visible-xs">
            <div class="pt_4 pb_4 pl_8 pr_4 map_lgray f_w_nw d_f def_lblack a_i_c m-badge-spec pos_rel">
                <div class="mr_8 fs_12 lh_16 fw_m stroke_crop f_g_1">Льготная ипотека</div>
                <div v-popover="{
                  trigger: 'hover',
                  placement: 'top',
                  content: getPopoverContent(),
                  html: true,
                }" class="w_16 d_f a_i_c">
                    <svg fill="rgba(66, 66, 66, 0.5)" class="w_16 va_m op_5">
                        <use xlink:href="#help-24"></use>
                    </svg>
                </div>
            </div>
        </div>-->

      <!-- Listing program of banks -->
      <Item v-for="(mortgageItem, key) in mortgageList" :key="key" :data="mortgageItem"></Item>

    </div>
</template>

<script>
  import Item from './item/MortgageItemComponent';
  import { mapState, mapGetters } from 'vuex';

  export const topList = [
    {
      title: 'Первое место по количеству выданных кредитов в {name} на основе данных Росреестра',
      color: '#fdb23b'
    },
    {
      title: 'Второе место по количеству выданных кредитов в {name} на основе данных Росреестра',
      color: '#b9b7b5'
    },
    {
      title: 'Третье место по количеству выданных кредитов в {name} на основе данных Росреестра',
      color: '#d78c36'
    }
  ];

  export default {
    components: { Item },
    name: 'BankItem',
    props: {
      index: {
        type: Number
      },
      bank: {
        type: Object,
        required: true
      },
      mortgageList: {
        type: Array,
        default: () => []
      },
    },
    computed: {
      ...mapState({
        build: 'novosItem',
        icons: 'icons',
        preferentialMortgageName: 'preferentialMortgageName'
      }),
      ...mapGetters({
        tags: 'tags'
      }),
      top() {
        let data = topList[this.index] || null;

        if (data) {
          data.title = data.title.replace('{name}', this.build.name_pred);
        }

        return data;
      },
      // @return {boolean}
      isPreferentialMortgage() {
        return this.mortgageList.findIndex(({ tags }) => {
          return tags.filter(({ name }) => name === this.preferentialMortgageName).length > 0;
        }) !== -1;
      }
    },
    methods: {
      notFound(e) {
        e.target.src = '/images/banks/blank-logo-mini.svg';
      },
      getPopoverContent() {
        return `
            <div class="sm_tooltip_box">
                <div class="sm_tooltip_content">
                    <div class="pt_10 pb_10">
                        <div class="content_title">
                            Программа «Ипотека с господдержкой 2020» действует до 1 июля 2021 года.<br/>
                            Пониженная ставка распространяется на весь срок кредитования — до 30 лет.<br/>
                            Максимальная сумма кредита — 12 млн рублей.
                        </div>
                    </div>
                </div>
            </div>
        `
      },
    },
  }
</script>
