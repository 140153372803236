<template>
    <div class="mb_20">
        <Loader v-if="loader"></Loader>
        <div v-else-if="loader === false && data.length > 0">
            <h2 class="card_h2 mt_20">Рассрочка</h2>

            <div class="b-box-listing-content one-table-title">
              <div class="b-listing-body">
                <div
                    v-for="(item, key) in data"
                    :key="key"
                    v-if="(key + 1) <= showItems"
                    class="b-listing-body-item pl_20 pr_20">
                  <Item :data="item"></Item>
                </div>

                <div v-if="hiddenCount > 0" class="b-listing-body-add pl_20 pr_20">
                  <div @click.prevent="showAllItems" class="b-listing-show-item">
                    {{ renderTextMoreAll(data) }}
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { TextHelper } from "../../helpers/TextHelper";
  import { mapState } from 'vuex';
  import Loader from '../Loader';
  import Item from './PaymentItemComponent';

  export default {
    name: 'PaymentList',
    components: { Loader, Item },
    watch: {
      houseItem() {
        this.getList();
      },
    },
    computed: {
      ...mapState({
        url: 'url',
        novosItem: 'novosItem',
        houseItem: 'houseItem',
        params: 'params',
        pageItems: 'pageItems',
        company: 'companyId',
        novosList: 'novosList',
        typeLoad: 'typeLoad',
        loader: 'paymentListLoader',
        data: 'paymentList'
      }),
      hiddenCount() {
        return this.data.length - this.showItems;
      }
    },
    data() {
      return {
        showItems: 0,
      }
    },
    created() {
      this.showItems = this.pageItems;
    },
    mounted() {
      this.getList();
    },
    methods: {
      showAllItems() {
        this.showItems = this.data.length;
      },

      getList() {
        this.$store.dispatch('getPayments');
      },

      renderTextMoreAll(data) {
        let items = data.length - this.pageItems;
        let text = TextHelper.declensionWords(items, ['программа', 'программы', 'программ']);
        return `ещё ${items} ${text}`;
      },
    }
  }
</script>