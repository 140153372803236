<script>
  import SendFormComponent from '../../quiz/components/SendFormComponent'

  export default {
    name: 'SendFormComponent',
    mixins: [ SendFormComponent ],
    data: () => ({
      urlSender: '/quiz/api/mortgage-send'
    }),
  };
</script>