import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    rc: null,
    moreBtnRc: null,
    mpnType: '',
    notAdvertsArray: [],
    isShowPrice: true,
    isVisibleHouse: true,
    isVisibleRooms: true,
    isVisibleBuild: true,
    isOptimalParams: true,
    isVisiblePayments: true,
    isVisibleLeader: true,
    isVisibleAdvert: true,
    isRoom: true,
    loader: false,
    novosList: [],
    novosItem: null,
    houseItem: null,
    roomItem: null,
    sellers: [],
    novosId: 0,
    houseId: 0,
    apartmentId: 0,
    companyId: 0,
    price: 0,
    typeLoad: 'one',
    pageItems: 0,
    url: '',
    params: {
      sum: 0,
      fee: 0,
      period: 0,
      rooms: '',
      house_id: 0,
      tags: []
    },
    tools: {
      show_for_clients: false,
      show_special_conditions: true,
      show_types_of_programs: false,
      button_advert_text: 'Оставить заявку'
    },
    settings: {
      minPrice: 0,
      maxPrice: 0,
      minFee: 0,
      maxFee: 0,
      maxPeriod: 0,
      minPeriod: 0
    },
    preferentialMortgageName: 'Господдержка',
    hasFilterPreferentialMortgage: false,
    isPreferentialMortgage: false,
    showOnlyAdvertiser: false,
    paymentListLoader: false,
    paymentList: [],
    bankListLoader: false,
    bankList: [],
    adverts: [],
    icons: {
      'Военная ипотека': '#cup-24',
      'Зарплатный проект': '#cup-24',
      'Семейная ипотека': '#cup-24',
      'Стандартная ипотека': '#cup-24'
    }
  },
  mutations: {
    setRc(state, value) {
      state.rc = value;
    },

    setMoreBtnRc(state, value) {
      state.moreBtnRc = value;
    },

    setTools(state, value) {
      state.tools = {...state.tools, ...value};
    },

    setApartmentId(state, value) {
      state.apartmentId = value;
    },

    setFilterPreferentialMortgage(state, value) {
      state.hasFilterPreferentialMortgage = value;
    },

    setShowOnlyAdvertiser(state, value) {
      state.showOnlyAdvertiser = value;
    },

    setPreferentialMortgage(state, value) {
      state.isPreferentialMortgage = value;
    },

    setMpnType(state, value) {
      state.mpnType = value;
    },

    setSellers(state, value) {
      state.sellers = value;
    },

    setBankListLoader(state, value) {
      state.bankListLoader = value;
    },

    setBankList(state, value) {
      state.bankList = value;
    },

    setMortgageItem(state, value) {
      state.bankList = state.bankList.map(item => {
        if (item.id === value.id) {
          return value;
        }
        return item;
      });
    },

    setAdverts(state, value) {
      state.adverts = value;
    },

    setPaymentList(state, value) {
      state.paymentList = value;
    },

    setPaymentListLoader(state, value) {
      state.paymentListLoader = value;
    },

    setPageItems(state, value) {
      state.pageItems = value;
    },

    setPrice(state, value) {
      state.price = value;
    },

    setTypeLoad(state, value) {
      if (value) {
        state.typeLoad = value;
      }
    },

    setSettings(state, { minPrice, maxPrice, minFee, maxFee, maxPeriod, minPeriod }) {
      state.settings = {
        minPrice: +minPrice,
        maxPrice: +maxPrice,
        minFee: parseInt(minFee),
        maxFee: parseInt(maxFee),
        maxPeriod: parseInt(maxPeriod / 12),
        minPeriod: parseInt((minPeriod < 12 ? 12 : minPeriod) / 12)
      };
    },

    setParams(state, params = {}) {
      state.params = {...state.params, ...params};
    },

    setRoomItem(state, value) {
      state.roomItem = value;
    },

    setHouseItem(state, value) {
      state.houseItem = value;
    },

    setNovosItem(state, novosItem) {
      state.novosItem = novosItem;
    },

    setNovosList(state, value) {
      state.novosList = value;
    },

    setLoader(state, value) {
      state.loader = value;
    },

    setPaymentParams(state, value) {
      state.paymentParams = value;
    },

    setUrl(state, value) {
      state.url = value;
    },

    setNotAdverts(state, value) {
      state.notAdvertsArray = value;
    },

    setShowPrice(state, value) {
      state.isShowPrice = value;
    },

    setVisibleHouse(state, value) {
      state.isVisibleHouse = value;
    },

    setVisibleRooms(state, value) {
      state.isRoom = value;
    },

    setVisibleBuild(state, value) {
      state.isVisibleBuild = value;
    },

    setOptimalParams(state, value) {
      state.isOptimalParams = value;
    },

    setVisibleLeader(state, value) {
      state.isVisibleLeader = value;
    },

    setVisibleAdvert(state, value) {
      state.isVisibleAdvert = value;
    },

    setNovosId(state, value) {
      state.novosId = value;
    },

    setHouseId(state, value) {
      state.houseId = value;
    },

    setCompanyId(state, value) {
      state.companyId = value;
    }
  },
  getters: {
    tags(state) {
      const tags = {};

      state.bankList.forEach(item =>
        item.tags ? item.tags.forEach(tag => !tags[tag.id] ? tags[tag.id] = tag : null) : null
      );

      return Object.keys(tags).map(key => tags[key]);
    },

    sortableBankList(state) {
      return state.bankList.sort((a, b) => {
        if (!a.active) {
          return 1;
        }

        if (!b.active) {
          return -1;
        }

        if (a.result === b.result) {
          return +a.advertiser === 1 ? -1 : (+b.advertiser === 1 ? 1 : 0);
        }

        return Number(String(a.result).replace(/\D+/, '')) < Number(String(b.result).replace(/\D+/, '')) ? -1 : 1;
      });
    },

    isAdvertiser(state) {
      return state.bankList
        .some(({ advertiser }) => +advertiser === 1)
          && state.isVisibleAdvert
          && state.tools.show_special_conditions;
    },

    advertiserBanks(state, getters) {
      return getters.sortableBankList
        .filter(({ advertiser }) => +advertiser === 1);
    },

    bankList(state, getters) {
      let data = state.showOnlyAdvertiser && getters.isAdvertiser
        ? getters.advertiserBanks.concat()
        : getters.sortableBankList.concat();

      if (state.params.tags && state.params.tags.length) {
        return data.filter(({ tags }) =>
          tags.some(({ id }) =>
            state.params.tags.some(tag => +tag === +id)
          )
        )
      }

      return data;
    }
  },
  actions: {
    getBuildList({ state, commit }) {
      return new Promise(async resolve => {
        if (state.novosList.length) {
          commit('setNovosItem', state.novosList[0]);
          resolve(state.novosList[0]);
        } else {
          commit('setLoader', true);

          try {
            const { data } = await window.axios.post(`${state.url}/get-list-novoses`, {
              novosId: +state.novosId,
              isRoom: !!state.isRoom,
              companyId: parseInt(state.companyId),
              sellers: state.sellers
            });

            commit('setNovosList', data);
            commit('setNovosItem', data[0]);
            commit('setLoader', false);
            resolve(data[0]);
          } catch (e) {
            console.error(e.message);
          }
        }
      });
    },

    actionNovos({ state, commit, dispatch }, novosItem) {
      commit('setNovosItem', novosItem);

      state.isShowPrice = !(novosItem.hasOwnProperty('hide_price') && +novosItem.hide_price > 0);

      if (state.typeLoad === 'many') {
        const minPrice = Math.min(...state.novosList.map(({ min_price }) => +min_price));
        const maxPrice = Math.max(...state.novosList.map(({ max_price }) => +max_price));
        const minFee = Math.min(...state.novosList.map(({ min_fee }) => +min_fee));
        const maxFee = Math.max(...state.novosList.map(({ max_fee }) => +max_fee));
        const minPeriod = Math.min(...state.novosList.map(({ min_credit_month }) => +min_credit_month));
        const maxPeriod = Math.max(...state.novosList.map(({ max_credit_month }) => +max_credit_month));

        commit('setSettings', { minPrice, maxPrice, minFee, maxFee, minPeriod, maxPeriod });
      } else {
        if (novosItem.houseEach.length > 0) {
          const houseId = +state.houseId || +state.params.house_id;
          dispatch('actionHouse', novosItem.houseEach.find(({ id }) => +id === houseId) || novosItem.houseEach[0]);
        } else {
          commit('setHouseItem', null);

          const { min_price, max_price, min_fee, max_fee, max_credit_month, min_credit_month } = novosItem;

          commit('setSettings', {
            minPrice: min_price,
            maxPrice: max_price,
            minFee: min_fee,
            maxFee: max_fee,
            minPeriod: min_credit_month,
            maxPeriod: max_credit_month
          });
        }
      }

      return novosItem;
    },

    actionHouse({ commit, state, dispatch }, houseItem) {
      commit('setHouseItem', houseItem);

      if (state.isRoom && houseItem.adsPrices.length > 0) {
        dispatch('actionRoom', houseItem.adsPrices.find(({ rooms }) =>
          String(rooms) === String(state.params.rooms)
        ) || houseItem.adsPrices[0]);
      } else {
        const { min_price, max_price, min_fee, max_fee, max_credit_month, min_credit_month } = houseItem;

        commit('setSettings', {
          minPrice: min_price,
          maxPrice: max_price,
          minFee: min_fee,
          maxFee: max_fee,
          minPeriod: min_credit_month,
          maxPeriod: max_credit_month
        });
      }
    },

    actionRoom({ commit, state, dispatch }, roomItem) {
      commit('setRoomItem', roomItem);

      const { min_price, max_price } = roomItem;
      const { min_fee, max_fee, max_credit_month, min_credit_month } = state.houseItem;

      commit('setSettings', {
        minPrice: min_price,
        maxPrice: max_price,
        minFee: min_fee,
        maxFee: max_fee,
        minPeriod: min_credit_month,
        maxPeriod: max_credit_month
      });
    },

    async getPayments({ commit, state }) {
      if (state.paymentListLoader === true) {
        return false;
      }

      commit('setPaymentListLoader', true);

      try {
        const { data } = await (
          state.typeLoad === 'one'
            ? window.axios.post(`${state.url}/get-list-payment/${state.novosItem.id}`, {
              houses: state.houseItem ? state.houseItem.houses : [],
              companyId: +state.companyId
            })
            : window.axios.post(`${state.url}/get-list-payment-all`, {
              houses: state.houseItem ? state.houseItem.houses : [],
              companyId: +state.companyId,
              list: state.novosList.map(({ id }) => +id)
            })
        );

        if (data.hasOwnProperty('status') && data.status === 'error') {
          console.warn(data.errors);
          commit('setPaymentListLoader', false);
        } else {
          commit('setPaymentList', [...data].map(item => ({...item, result: 0, active: true})));
          commit('setPaymentListLoader', false);
        }
      } catch (e) {
        console.error(e.message);
        commit('setPaymentListLoader', false);
      }
    },

    async getBanks({ commit, state, getters }) {
      if (state.bankListLoader === true) {
        return false;
      }

      commit('setBankListLoader', true);

      try {
        const params = {
          houses: state.houseItem ? state.houseItem.houses : [],
          notAdverts: state.notAdvertsArray,
          isVisibleAdvert: state.isVisibleAdvert ? 1 : 0
        };

        if (state.apartmentId > 0) {
          params.apartmentId = state.apartmentId;
        }

        const { data } = await (
          state.typeLoad === 'one'
            ? window.axios.post(`${state.url}/get-list-mortgage/${state.novosItem.id}`, params)
            : window.axios.post(`${state.url}/get-list-mortgage-all`, {
              ...params,
              list: state.novosList.map(({ id }) => +id)
            })
        );

        if (data.hasOwnProperty('status') && data.status === 'error') {
          console.warn(data.errors);
          commit('setBankListLoader', false);
        } else {
          //commit('setAdverts', data.adverts);
          commit('setBankList', data.list.map((item, index) => ({
            ...item,
            active: false,
            result: 0,
            sortable: index + 1,
            errors: []
          })));
          commit('setBankListLoader', false);
          commit('setFilterPreferentialMortgage', data.list.some(({ tags }) =>
            tags.some(({ name }) => name === state.preferentialMortgageName)
          ));
          commit('setParams', {...state.params, tags: []});

          window.emit.emit('init.mortgage.list', this);
        }
      } catch (e) {
        console.error(e.message);
        commit('setBankListLoader', false);
      }
    },
  }
});
