<template>
    <form @submit="$event.preventDefault()">
        <loader v-if="loader" class="mt_0 mb_0 pt_5 pb_5" />

        <div v-else class="mb_20">
            <loader v-if="loaderSend" class="mt_0 mb_0 pt_5 pb_5" />

            <FieldSliderComponent
                v-if="value > 0"
                :options="prices"
                v-model="value"
                append-field="₽"
                :numberFormat="true"
            />

<!--            <div-->
<!--                class="poll-border bl-theme-interactive br_2 c_p mb_10"-->
<!--                v-for="field in prices"-->
<!--                :class="{ active: value === field }"-->
<!--            >-->
<!--                <label class="checkbox_item checker_inline">-->
<!--                    <div class="p_14">-->
<!--                        <input-->
<!--                            :name="formData.name"-->
<!--                            type="radio"-->
<!--                            :value="field"-->
<!--                            :checked="value === field"-->
<!--                            @change="value = field"-->
<!--                        >-->
<!--                        <span class="checker_item mt_14 ml_14 br_all"></span>-->
<!--                        <span class="checkbox_item_title">до {{ priceFormat(field) }} руб.</span>-->
<!--                    </div>-->
<!--                </label>-->
<!--            </div>-->
<!--            <div-->
<!--                class="poll-border bl-theme-interactive br_2 c_p mb_10"-->
<!--                :class="{ active: value === lastNamePrice }"-->
<!--            >-->
<!--                <label class="checkbox_item checker_inline">-->
<!--                    <div class="p_14">-->
<!--                        <input-->
<!--                            :name="formData.name"-->
<!--                            type="radio"-->
<!--                            value="last"-->
<!--                            :checked="value === lastNamePrice"-->
<!--                            @change="value = lastNamePrice"-->
<!--                        >-->
<!--                        <span class="checker_item mt_14 ml_14 br_all"></span>-->
<!--                        <span class="checkbox_item_title">более 100 000 руб.</span>-->
<!--                    </div>-->
<!--                </label>-->
<!--            </div>-->
        </div>

        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <div class="d_f">
            <button
                v-if="!isFirst"
                :disabled="loader || loaderSend"
                @click="$store.commit('prev')"
                class="p_15 bgr_f5f5f5 name_color d_i_f mr_10 br_2 btn-svg-item-2"
            >
                <div class="">
                    <svg fill="#212121" class="w_24">
                        <use xlink:href="#arrowback-24"/>
                    </svg>
                </div>
                <div class="ml_15 fs_16 lh_24 fw_m hidden-xs pr_5">
                    Назад
                </div>
            </button>
            <button
                :disabled="!isValid || loaderSend || loader"
                @click="getResult"
                class="p_15 bgr_f5f5f5 name_color btn-svg-item br_2 pl_20 d_f "
            >
                <div class="fs_16 lh_24 fw_m ">
                    {{ isLast ? 'Показать результат' : 'Следующий вопрос' }}
                </div>
                <div class=" ml_15 ">
                    <svg fill="currentColor" class="w_24">
                        <use xlink:href="#arrowforward-24"/>
                    </svg>
                </div>
            </button>
        </div>
    </form>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import { TextHelper } from '../../../helpers/TextHelper'
  import FieldSliderComponent from '../../mortgage-calc/components/FieldSliderComponent'

  export default {
    name: 'PayMonthForm',
    components: {
      FieldSliderComponent
    },
    computed: {
      ...mapGetters({
        formData: 'getForm',
        isLast: 'isLast',
        isFirst: 'isFirst',
      }),
      ...mapState('mortgage', {
        prices: 'prices',
        loader: 'minPayMonthLoader'
      }),
      ...mapState({
        form: 'form',
        step: 'step',
        error: 'error',
        result: 'result',
        loaderSend: 'loaderSend'
      }),
      value: {
        get() {
          const key = this.formData.name;
          if (this.form && typeof this.form === 'object' && this.form.hasOwnProperty(key)) {
            return this.form[key];
          }
          return null;
        },
        set(value) {
          this.$store.commit('setForm', {...this.form, [this.formData.name]: value});
        }
      },
      isValid() {
        return this.value !== null;
      }
    },
    data: () => ({
      lastNamePrice: 'last'
    }),
    async created() {
      await this.load();
    },
    methods: {
      async load() {
        const { min } = await this.$store.dispatch('mortgage/getMinPayMonth', this.form);
        if (this.value === null || this.value < min) {
          this.value = min;
        }
      },

      async getResult() {
        await this.$store.dispatch('mortgage/getTotal');
        if (this.result) {
          this.$store.commit('next');
        }
      },

      priceFormat(value) {
        return TextHelper.priceFormat(value);
      }
    }
  }
</script>